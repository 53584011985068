<template>
    <div>
        <div class="filter-panel">
            <input
                    type="text"
                    placeholder="搜索个人名称/对接人"
                    style="margin-left: 0"
                    v-model="keyword"
                    class="cs-input"
            />
            <button
                    type="button"
                    class="btn btn-primary btn-sm"
                    @click="queryIndividual()"
            >
                查询
            </button>
        </div>

        <div class="result-panel">
            <CSTable :thead-top="60">
                <template v-slot:header>
                    <div class="table-header-panel" style="text-align: right">
                        <button
                                class="btn btn-primary sticky-right"
                                @click="openAddIndividualInfo()"
                        >
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-menujiahao"></use>
                            </svg>
                            个人
                        </button>
                    </div>
                </template>
                <template v-slot:thead>
                    <tr>
                        <th class="text-center">添加时间</th>
                        <th class="text-center">个人名称</th>
                        <th class="text-center">身份证号</th>
                        <th class="text-center">身份证</th>
                        <th class="text-center">对接人</th>
                        <th class="text-center">备注</th>
                        <th class="text-center">操作</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <tr v-for="individual in individualList" :key="individual.id">
                        <td class="text-center" style="min-width: 125px">
                            {{ individual.createTime }}
                        </td>
                        <td>
              <span
                      v-if="individual.name"
                      class="allow-click"
                      @click="checkName(individual)"
              >
                {{ individual.name }}
              </span>
                            <template v-else> -</template>
                        </td>
                        <td class="text-center">
                            {{ individual.code || "-" }}
                        </td>
                        <td>
              <span
                      v-if="individual.licese"
                      class="allow-click"
                      @click="checkLicese(individual)"
              >
                查看
              </span>
                            <template v-else> -</template>
                        </td>
                        <td class="text-center">
                            {{ individual.deputyName }} -
                            {{
                            individual.deputyGender === 1
                            ? "男"
                            : individual.deputyGender === 2
                            ? "女"
                            : "未知"
                            }}
                            -
                            {{ individual.deputyPhone }}
                        </td>
                        <td>
              <span
                      v-if="individual.remark"
                      class="allow-click"
                      @click="checkRemark(individual)"
              >
                查看
              </span>
                            <template v-else> -</template>
                        </td>
                        <td class="text-center">
                            <div class="btn-group">
                                <button
                                        type="button"
                                        class="btn btn-primary dropdown-toggle"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                >
                                    操作
                                </button>
                                <!--                                                @click="openDelOwnerModel(tenant)"-->

                                <ul class="dropdown-menu">
                                    <li>
                                        <a
                                                style="width: 100%; text-decoration: none"
                                                @click="modifyEnterprise(individual)"
                                        >
                                            修改对接人
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                                style="width: 100%; text-decoration: none"
                                                @click="openEditIndividualInfo(individual)"
                                        >
                                            修改个人
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                                style="width: 100%; text-decoration: none"
                                                @click="deleteIndividualInfo(individual.id)"
                                        >
                                            删除个人
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </template>
            </CSTable>
            <Pagination/>
        </div>

        <!--查看个人信息-->
        <CSDialog
                dialog-title="查看个人信息"
                dialog-width="820px"
                dialog-visible
                :dialogVisible="individualInfoFlag"
                :dialogShowConfirmBtn="false"
                dialogCancelBtnText="关闭"
                @onClose="individualInfoFlag = false"
                dialog-header-class="alert-bg"
        >
            <template v-slot:dialog-content>
                <div class="dialog-content">
                    <div class="dialog-form">
                        <div class="dialog-form-field" style="margin-bottom: 10px">
                            <div class="field-name">个人姓名</div>
                            <div>{{ individualInfo.name }}</div>
                        </div>
                        <div class="dialog-form-field" style="margin-bottom: 10px">
                            <div class="field-name">身份</div>
                            <div>
                                {{ individualInfo.legalPerson }}
                            </div>
                        </div>
                        <div class="dialog-form-field" style="margin-bottom: 10px">
                            <div class="field-name">身份证</div>
                            <div>
                <span
                        style="
                    display: inline-block;
                    margin: 0 auto;
                    text-align: left;
                  "
                >
                  <img
                          :src="individualInfo.licese"
                          alt=""
                          style="width: 200px"
                          @click="checkLicese(individualInfo)"
                  />
                </span>
                            </div>
                        </div>
                        <div class="dialog-form-field" style="margin-bottom: 10px">
                            <div class="field-name">身份证号</div>
                            <div>
                                {{ individualInfo.code }}
                            </div>
                        </div>
                        <div class="dialog-form-field" style="margin-bottom: 10px">
                            <div class="field-name">对私账号</div>
                            <div>
                                {{ individualInfo.account }}
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </CSDialog>

        <!--添加个人信息-->
        <CSDialog
                :dialogTitle="`添加个人`"
                :dialog-visible="addIndividualFlag"
                dialog-width="1000px"
                @onClose="addIndividualFlag = false;personalNameSearchList = []"
                @onConfirm="addIndividualInfo"

        >

            <template v-slot:dialog-content>
                <div style="padding:15px 0">

                </div>
                <div class="dialog-form" @click="personalNameSearchList = [];">
                    <div class="dialog-form-item">
                        <div class="dialog-form-item-label">个人名称</div>
                        <div>
                            <input
                                    type="text"
                                    style="
                  width: 347px;
                  height: 40px;
                  padding: 0px 10px;
                  border: 1px solid #979797;
                  border-radius: 4px;
                "
                                    placeholder="限10个字"
                                    v-model="individual.name"
                                    @input="searchName"
                                    @click="searchName"
                            />
                            <ul style="list-style:none;
                            position: absolute;
                            z-index: 9999;
                            background-color: white;
                            border: 2px solid #979797;
                            width: 347px;
                            padding:10px 0 10px 10px;
                            border-radius: 4px;
                            font-size: 14px"
                                v-if="personalNameSearchList.length>0">

                                <li :key="item.id" v-for="item in personalNameSearchList">{{item.name+`（`+item.code +
                                    `）`}}
                                </li>

                            </ul>
                        </div>
                    </div>
                    <div class="dialog-form-item">
                        <div class="dialog-form-item-label">身份</div>
                        <div>
                            <input
                                    type="text"
                                    style="
                  width: 347px;
                  height: 40px;
                  padding: 0px 10px;
                  border: 1px solid #979797;
                  border-radius: 4px;
                "
                                    placeholder="限5个字"
                                    v-model="individual.legalPerson"
                            />
                        </div>
                    </div>

                    <div class="dialog-form-item">
                        <div class="dialog-form-item-label" style="margin-bottom:46px;line-height: 21px;">身份证</div>
                        <div v-if="individual.licese !== ''" style="position: relative">
                            <img
                                    :src="individual.licese"
                                    @click="uploadAddIndividualPhoto"
                                    style="width: 100px; height: 100px"
                            />
                            <img
                                    @click="deleteFacePhoto()"
                                    src="../../assets/icon4.png"
                                    style="
                  width: 15px;
                  height: 15px;
                  position: absolute;
                  top: -5px;
                  right: -5px;
                "
                            />
                        </div>
                        <div v-else>
                            <div class="upload-photo" @click="uploadAddIndividualPhoto">
                                <div class="text-center">
                                    <img
                                            src="../../assets/upload.png"
                                            alt=""
                                            style="margin: 5px auto"
                                    />
                                    <p style="color: #999">上传照片</p>
                                </div>
                            </div>
                        </div>
                        <input
                                type="file"
                                class="file"
                                accept="images/*"
                                id="uploadAddIndividualPhotoId"
                                hidden
                                @change="showImage($event)"
                        />
                    </div>

                    <div class="dialog-form-item">
                        <div class="dialog-form-item-label">身份证号</div>
                        <div>
                            <input
                                    type="text"
                                    style="
                  width: 347px;
                  height: 40px;
                  padding: 0px 10px;
                  border: 1px solid #979797;
                  border-radius: 4px;
                "
                                    placeholder="限18位"
                                    v-model="individual.code"
                            />
                        </div>
                    </div>
                    <div class="dialog-form-item">
                        <div class="dialog-form-item-label">对私账号</div>
                        <div>
                            <input
                                    type="text"
                                    style="
                  width: 347px;
                  height: 40px;
                  padding: 0px 10px;
                  border: 1px solid #979797;
                  border-radius: 4px;
                "
                                    placeholder="请输入"
                                    v-model="individual.account"
                            />
                        </div>
                    </div>
                    <div class="dialog-form-item">
                        <div class="dialog-form-item-label" style="vertical-align: top">
                            对接人
                        </div>

                        <div style="width: 347px">
                            <ChooseLocation
                                    mission="deputy"
                                    style="--readonly-input-width: 307px"
                                    @changeLocation="changeLocation"
                                    :showInput="false"
                            />
                        </div>
                    </div>
                    <div class="dialog-form-item">
                        <div class="dialog-form-item-label" style="margin-bottom:170px;line-height: 21px;">备注</div>
                        <div class="field">
              <textarea
                      style="
                  width: 720px;
                  height: 200px;
                  padding: 0 15px;
                  resize: none;
                  border: 0px;
                  border-radius: 10px;
                  background-color: #f0f0f0;
                "
                      maxlength="200"
                      placeholder="限200字，非必填"
                      v-model="individual.remark"
              ></textarea>
                        </div>
                    </div>
                </div>
                <div style="padding:15px 0">

                </div>
            </template>
        </CSDialog>

        <!--修改个人信息-->
        <CSDialog
                :dialogTitle="`修改个人`"
                :dialog-visible="editIndividualFlag"
                dialog-width="1000px"
                @onClose="editIndividualFlag = false"
                @onConfirm="editIndividual"
        >
            <template v-slot:dialog-content>
                <div class="dialog-form">
                    <div class="dialog-form-item">
                        <div class="dialog-form-item-label">个人名称</div>
                        <div>
                            <input
                                    disabled="false"
                                    type="text"
                                    style="
                  width: 347px;
                  height: 40px;
                  padding: 0px 10px;
                  border: 1px solid #979797;
                  border-radius: 4px;
                "
                                    placeholder="限10个字"
                                    v-model="editIndividualInfo.name"
                            />
                        </div>
                    </div>
                    <div class="dialog-form-item">
                        <div class="dialog-form-item-label">身份</div>
                        <div>
                            <input
                                    disabled="false"
                                    type="text"
                                    style="
                  width: 347px;
                  height: 40px;
                  padding: 0px 10px;
                  border: 1px solid #979797;
                  border-radius: 4px;
                "
                                    placeholder="限5个字"
                                    v-model="editIndividualInfo.legalPerson"
                            />
                        </div>
                    </div>

                    <div class="dialog-form-item">
                        <div class="dialog-form-item-label" style="margin-bottom:60px;line-height: 21px;">身份证</div>
                        <div v-if="editIndividualInfo.licese !== ''" style=" position: relative">
                            <img
                                    :src="editIndividualInfo.licese"
                                    @click="uploadEditIndividualPhoto"
                                    style="width: 100px;height:100px"
                            />
                        </div>
                        <input
                                disabled="false"
                                type="file"
                                class="file"
                                accept="images/*"
                                id="uploadEditIndividualPhotoId"
                                hidden
                                @change="showImage2($event)"
                        />
                    </div>

                    <div class="dialog-form-item">
                        <div class="dialog-form-item-label">身份证号</div>
                        <div>
                            <input
                                    disabled="false"
                                    type="text"
                                    style="
                  width: 347px;
                  height: 40px;
                  padding: 0px 10px;
                  border: 1px solid #979797;
                  border-radius: 4px;
                "
                                    placeholder="限18位"
                                    v-model="editIndividualInfo.code"
                            />
                        </div>
                    </div>
                    <div class="dialog-form-item">
                        <div class="dialog-form-item-label">对私账号</div>
                        <div>
                            <input
                                    type="text"
                                    style="
                  width: 347px;
                  height: 40px;
                  padding: 0px 10px;
                  border: 1px solid #979797;
                  border-radius: 4px;
                "
                                    placeholder="请输入"
                                    v-model="editIndividualInfo.account"
                            />
                        </div>
                    </div>
                    <div class="dialog-form-item">
                        <div class="dialog-form-item-label" style="vertical-align: top">
                            对接人
                        </div>
                        <div style="width: 347px">
                            <ChooseLocation
                                    mission="deputy"
                                    style="--readonly-input-width: 307px"
                                    :default-location-name="editIndividualInfo.location"
                                    @changeLocation="changeLocation2"
                                    :showInput="false"
                            />
                        </div>
                    </div>
                    <div class="dialog-form-item">
                        <div class="dialog-form-item-label" style="margin-bottom:170px;line-height: 21px;">备注</div>
                        <div class="field">
              <textarea
                      style="
                  width: 720px;
                  height: 200px;
                  padding: 0 15px;
                  resize: none;
                  border: 0px;
                  border-radius: 10px;
                  background-color: #f0f0f0;
                "
                      maxlength="200"
                      placeholder="限200字，非必填"
                      v-model="editIndividualInfo.remark"
              ></textarea>
                        </div>
                    </div>
                </div>
            </template>
        </CSDialog>

        <CSDialog
                :dialogTitle="`查看身份证`"
                :dialog-visible="individualShow"
                dialog-width="750px"
                @onClose="individualShow=false"
                dialogCancelBtnText="关闭"
                class="oppositeName"
                :dialogShowConfirmBtn="false"
                dialogHeaderClass="alert-bg"
        >
            <div slot="dialog-content" class="room-form">
                <CSImage :srcImg="individualImg?individualImg.licese:''">

                </CSImage>
            </div>

        </CSDialog>
        <!--修改对接人-->
        <CSDialog
                :dialogTitle="`修改对接人`"
                :dialog-visible="oppositePerson"
                dialog-width="475px"
                @onClose="
        oppositePerson = false;
        deputySave = {};
      "
                @onConfirm="editOppositePerson"
                class="oppositeName"
        >
            <div slot="dialog-content" class="room-form">
                <div class="field-item">
                    <label
                            class="field-item-label"
                            style="width: 80px; margin-right: 20px"
                    >对接人</label
                    >
                    <div class="field-item-content" style="position: relative">
                        <CSSelect height="40px" iWidth="36px" style="width: 305px">
                            <DropDownSelection
                                    @queryInfo="queryInfo"
                                    @selectWarte="selectValue"
                                    :dataList="modifyEnterpriseList"
                                    :item="deputyItem"
                                    :placeholderName="'搜索姓名/手机号'"
                                    type="deputy"
                            ></DropDownSelection>
                        </CSSelect>
                    </div>
                </div>
            </div>
        </CSDialog>
    </div>
</template>

<script>
    import {
        addIndividual,
        queryIndividualList,
        removeIndividual,
        editIndividual,
        queryUserByPhoneOrName,
        editDeputyPhone,
        personalNameSearchUrl,
    } from "@/requestUrl";
    import CSTable from "@/components/common/CSTable";
    import CSDialog from "@/components/common/CSDialog";
    import Pagination from "@/components/Pagination.vue";
    import ChooseLocation from "@/components/ChooseLocation";
    import DropDownSelection from "@/components/DropDownSelection.vue";
    import CSSelect from "@/components/common/CSSelect";
    import CSImage from "@/components/common/CSImage";
    export default {
        name: "Individual",
        components: {
            CSTable,
            Pagination,
            CSDialog,
            ChooseLocation,
            DropDownSelection,
            CSSelect,
            CSImage
        },
        data() {
            return {
                individualShow:false,
                individualImg:null,
                deputyItem: {}, //本条信息内容,用于回显
                deputySave: {},
                deputyId: "",
                modifyEnterpriseList: [], //企业对接人列表
                oppositePerson: false, //对接人显示框
                keyword: "",
                filterHeight: 0,
                individualList: [],
                addIndividualFlag: false, //添加个人
                individual: {
                    id: "",
                    name: "", //企业名称 个人名称
                    code: "", //统一社会信用代码/身份证号
                    legalPerson: "", //法人/身份
                    licese: "", //营业执照/身份证 url
                    account: "", //对公账号/对私账号
                    deputyId: "",
                    deputyName: "",
                    deputyGender: "",
                    deputyPhone: "",
                    remark: "",
                },
                individualInfoFlag: false,
                individualInfo: {},
                editIndividualFlag: false,
                editIndividualInfo: {},
                personalNameSearchList: [],
      timer: "",
      pageParams: {
        pageNo: 1,
        pageSize: 10
      }
            };
        },
        created() {
            this.queryIndividual();
            this.$vc.on(
                this.$route.path,
                "pagination_page",
        "event", pageNo => {
          this.pageParams.pageNo = pageNo;
          this.queryIndividual(pageNo);
        });
            window.addEventListener("keydown", this.queryIndividualDown);
        },
        beforeDestroy() {
            window.removeEventListener("keydown", this.queryIndividualDown);
        },
        deactivated() {
            window.removeEventListener("keydown", this.queryIndividualDown);
        },
        methods: {
            queryIndividualDown(e) {
                if (e.keyCode == 13) {
                    console.log('individual');
                    this.queryIndividual();
                }
            },
            //编辑对接人信息
            editOppositePerson() {
                if (JSON.stringify(this.deputySave) !== "{}") {
                    let params = {
                        id: this.deputyItem.id,
                        deputyId: this.deputySave.id,
                        deputyName: this.deputySave.userName,
                        deputyPhone: this.deputySave.phone,
                        deputyGender: this.deputySave.gender,
                    };
                    this.$fly.post(editDeputyPhone, params).then((res) => {
                        if (res.code !== 0) {
                            return;
                        }
                        this.oppositePerson = false;
                        this.deputySave = {};
                        this.$vc.toast("修改对接人成功");
                        this.queryIndividual();
                    });
                }

            },
            //选中的对接人
            selectValue(item) {
                this.deputySave = item;
            },
            //查询对接人
            queryInfo(search = "") {
                this.$fly
                    .get(queryUserByPhoneOrName, {search, deputyId: this.deputyId})
                    .then((res) => {
                        if (res.code != 0) {
                            return;
                        }
                        this.modifyEnterpriseList = res.data;
                    });
            },
            //修改企业对接人弹窗
            modifyEnterprise(item) {
                this.deputyId = item.deputyId;
                this.deputyItem = item;
                this.queryInfo();
                this.oppositePerson = true;
            },

            //查询个人信息列表
            queryIndividual(pageNo = this.pageParams.pageNo, pageSize = 10) {
                const params = {
                    regionCode: this.$vc.getCurrentRegion().communityId,
                    search: this.keyword,
                    pageNo,
                    pageSize,
                    type: 2,
                };
                this.$fly.post(queryIndividualList, params).then((res) => {
                    if (res.code !== 0) {
                        return;
                    } else {
                        if (pageNo === 1) {
                            this.$vc.emit(this.$route.path, "pagination", "init", {
                                total: res.data.total,
                                pageSize,
                                currentPage: pageNo,
                            });
                        }
                    }
                    this.individualList = res.data.datas;
                });
            },
            //删除个人
            deleteIndividualInfo(id) {
                this.$CSDialog.warning({
                    title: "提示",
                    messageHtml: "<span>确认删除吗?</span>",
                    onConfirm: () => {
                        this.$fly.get(removeIndividual, {id: id}).then((res) => {
                            if (res.code !== 0) {
                                return;
                            }
                            this.$vc.toast("删除成功");
                            this.queryIndividual();
                            this.$CSDialog.instance.closeDialog();
                        });
                    },
                });
            },
            //查看备注
            checkRemark(individual) {
                this.$CSDialog.alert({
                    title: "查看备注",
                    messageHtml: `<span style="display: inline-block;margin: 0 auto;text-align: left;">${individual.remark}</span>`,
                    width: "450px",
                });
            },
            //查看个人信息
            checkName(individual) {
                this.individualInfo = individual;
                this.individualInfoFlag = true;
            },
            //查看身份证照片
            checkLicese(individual) {
                this.individualImg = individual;
                console.log(this.individualImg);
                this.individualShow = true;
                // this.$CSDialog.alert({
                //     title: "查看身份证",
                //     messageHtml: `<span style="display: inline-block;margin: 0 auto;text-align: left;">
                //                              <img
                //                              src="${individual.licese}"
                //                              alt=""
                //                              style="height:400px"
                //                             />
                //                     </span>`,
                //     width: "750px",
                // });
            },
            async uploadPhoto(base64) {
                return new Promise((reslove, reject) => {
                    this.$vc.http.post(
                        "uploadImg",
                        "upload",
                        {
                            img: base64,
                        },
                        {
                            headres: {
                                "Content-Type": "application/json",
                            },
                        },
                        (resStr, res) => {
                            reslove(res.body.fileSaveName);
                        },
                        (errText, err) => {
                            reject(errText);
                        }
                    );
                });
            },

            //添加个人
            uploadAddIndividualPhoto() {
                $("#uploadAddIndividualPhotoId").trigger("click");
            },
            //添加个人 - 删除照片
            deleteFacePhoto() {
                this.individual.licese = "";
            },
            //添加个人 查看图片
            async showImage(event) {
                var photoFiles = event.target.files;
                if (photoFiles && photoFiles.length > 0) {
                    var file = photoFiles[0];
                    if (file.size > 1024 * 1024 * 2) {
                        this.$vc.toast("图片大小不能超过 2M!");
                        return false;
                    }
                }


                let formData = new FormData(); //新建FormData对象
                formData.append("files", photoFiles[0]);
                formData.append("bucketName", this.Constants.BUCKET_NAMES.IMG.name);
                formData.append("compress", "false");
                formData.append('fileName','身份证')
                const photo = await this.Constants.uploadFileOne(formData);
                if (photo) {
                    this.individual.licese =this.Constants.BUCKET_NAMES.IMG.visitUrl + photo;
                }


                // var reader = new FileReader();
                // reader.readAsDataURL(file);
                // reader.onloadend = async () => {
                //     let imgSrc = await this.uploadPhoto(reader.result).catch(() => {
                //         this.$vc.toast("上传图片错误,请重新上传");
                //     });
                //     if (imgSrc && imgSrc != "") {
                //         this.individual.licese = imgSrc;
                //     }
                //     event.target.value = "";
                // };
            },
            //添加个人 - 打开添加遮罩层
            openAddIndividualInfo() {
                this.addIndividualFlag = true;
            },
            // 添加个人信息
            addIndividualInfo() {
                const params = {
                    regionCode: this.$vc.getCurrentRegion().communityId,
                    name: this.individual.name,
                    code: this.individual.code,
                    legalPerson: this.individual.legalPerson,
                    licese: this.individual.licese,
                    account: this.individual.account,
                    deputyId: this.individual.deputyId,
                    deputyName: this.individual.deputyName,
                    deputyGender: this.individual.deputyGender,
                    deputyPhone: this.individual.deputyPhone,
                    remark: this.individual.remark,
                };
                this.$fly.post(addIndividual, params).then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    this.addIndividualFlag = false;
                    Object.keys(this.individual).forEach(
                        (key) => (this.individual[key] = "")
                    );
                    this.$vc.toast("添加个人成功");
                    this.queryIndividual();
                });
            },
            //添加改变对接人
            changeLocation(location) {
                console.log("改变的对接人" + JSON.stringify(location));
                this.individual.deputyId = location.id;
                let deputyName = `${location.name}`;
                let deputyGender = `${location.gender}`;
                let deputyPhone = `${location.phone}`;
                let locationInfo = "";
                if (
                    deputyName !== "undefined" &&
                    deputyName !== undefined &&
                    deputyName !== "null"
                ) {
                    this.individual.deputyName = `${location.name}`;
                    locationInfo += deputyName + "-";
                } else {
                    this.individual.deputyName = "未知";
                    locationInfo += deputyName + "-";
                }
                if (
                    deputyGender !== "undefined" &&
                    deputyGender !== undefined &&
                    deputyGender !== "null"
                ) {
                    this.individual.deputyGender = `${location.gender}`;
                    locationInfo +=
                        deputyGender === "1" ? "男-" : deputyGender === "2" ? "女-" : "未知-";
                }
                if (
                    deputyPhone &&
                    deputyPhone !== "undefined" &&
                    deputyPhone !== undefined &&
                    deputyPhone !== "null"
                ) {
                    this.individual.deputyPhone = `${location.phone}`;
                    locationInfo += deputyPhone;
                } else {
                    this.individual.deputyPhone = "未知";
                    locationInfo += "未知";
                }
                this.individual.location = locationInfo;
            },

            //修改个人
            uploadEditIndividualPhoto() {
                $("#uploadEditIndividualPhotoId").trigger("click");
            },
            showImage2(event) {
                var photoFiles = event.target.files;
                if (photoFiles && photoFiles.length > 0) {
                    var file = photoFiles[0];
                    if (file.size > 1024 * 1024 * 2) {
                        this.$vc.toast("图片大小不能超过 2M!");
                        return false;
                    }
                }
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = async () => {
                    let imgSrc = await this.uploadPhoto(reader.result).catch(() => {
                        this.$vc.toast("上传图片错误,请重新上传");
                    });
                    if (imgSrc && imgSrc != "") {
                        this.editIndividualInfo.licese = imgSrc;
                    }
                    event.target.value = "";
                };
            },
            openEditIndividualInfo(individual) {
                this.editIndividualInfo = {...individual};
                let location = {
                    id: individual.deputyId,
                    userName: individual.deputyName,
                    gender: individual.deputyGender,
                    phone: individual.deputyPhone,
                };
                this.changeLocation2(location);
                this.editIndividualFlag = true;
            },
            editIndividual() {
                let params = {
                    id: this.editIndividualInfo.id,
                    account: this.editIndividualInfo.account,
                    deputyId: this.editIndividualInfo.deputyId,
                    deputyName: this.editIndividualInfo.deputyName,
                    deputyGender: this.editIndividualInfo.deputyGender,
                    deputyPhone: this.editIndividualInfo.deputyPhone,
                    remark: this.editIndividualInfo.remark,
                };
                this.$fly.post(editIndividual, params).then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    this.editIndividualFlag = false;
                    this.$vc.toast("修改个人成功");
                    this.queryIndividual();
                });
            },
            //添加改变对接人
            changeLocation2(location) {
                this.editIndividualInfo.deputyId = location.id;
                let deputyName = `${location.name}`;
                let deputyGender = `${location.gender}`;
                let deputyPhone = `${location.phone}`;
                let locationInfo = "";
                if (
                    deputyName !== "undefined" &&
                    deputyName !== undefined &&
                    deputyName !== "null"
                ) {
                    this.editIndividualInfo.deputyName = `${location.name}`;
                    locationInfo += deputyName + "-";
                } else {
                    this.editIndividualInfo.deputyName = "未知";
                    locationInfo += deputyName + "-";
                }
                if (
                    deputyGender !== "undefined" &&
                    deputyGender !== undefined &&
                    deputyGender !== "null"
                ) {
                    this.editIndividualInfo.deputyGender = `${location.gender}`;
                    locationInfo +=
                        deputyGender === "1" ? "男-" : deputyGender === "2" ? "女-" : "未知-";
                }
                if (
                    deputyPhone !== "undefined" &&
                    deputyPhone !== undefined &&
                    deputyPhone !== "null"
                ) {
                    this.editIndividualInfo.deputyPhone = `${location.phone}`;
                    locationInfo += deputyPhone;
                }
                this.editIndividualInfo.location = locationInfo;
            },
            async searchName() {
                clearTimeout(
                    this.timer
                )
                this.timer = setTimeout(async () => {
                    let res = await this.$fly.get(personalNameSearchUrl, {search: this.individual.name, type: 2});
                    if (res.code !== 0) {
                        return;
                    }
                    this.personalNameSearchList = res.data;
                }, 200)
            },
        },

    };
</script>

<style lang="stylus" scoped>
    .dialog-form {
        padding: 0px 30px;
        font-size: 24px;
        color: #000;

        &-item {
            &:not(:last-of-type) {
                margin-bottom: 23px;
            }

            &-label {
                width: 206px;
                height: 33px;
                text-align: right;
                margin-right: 40px;
                display: inline-block;
                vertical-align: middle;
                line-height: 33px;
            }

            & > div {
                display: inline-block;
                vertical-align: middle;

                input {
                    &::placeholder {
                        color: #999;
                    }

                    width: 140px;
                    height: 40px;
                    border-radius: 4px;
                    padding: 0 5px;
                    border: 1px solid #979797;
                    vertical-align: middle;
                    padding-left: 10px;
                }
            }
        }
    }

    .dialog-content {
        padding: 30px;

        .dialog-form {
            &-field {
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                align-items: flex-start;
                font-size: 24px;

                &:not(:last-of-type) {
                    margin-bottom: 30px;
                }

                .field-name {
                    width: 240px;
                    margin-right: 40px;
                    text-align: right;
                    flex: 0 0 240px;
                }

                input {
                    border: 1px solid #979797;
                    border-radius: 4px;
                }

                .show-rule {
                    display: flex;
                    flex-flow: row nowrap;

                    // align-items center

                    &-name {
                        width: 120px;
                        flex: 0 0 120px;
                        margin-right: 40px;
                    }
                }
            }
        }

        .tips {
            display: flex;
            font-size: 20px;
            color: #999;
            flex-flow: row nowrap;
            justify-content: flex-start;
            margin-top: 16px;

            .icon {
                margin-top: 4px;
                margin-right: 10px;
            }

            p {
                margin-bottom: 0;
                line-height: 1.2;

                &:not(:last-of-type) {
                    margin-bottom: 4px;
                }
            }
        }
    }

    .room-form {
        padding: 27px 30px;

        .field-item {
            margin-bottom: 22px;
            font-size: 0;

            &-label {
                font-size: 24px;
                text-align: right;
                width: 208px;
                margin-right: 40px;
                display: inline-block;
                vertical-align: middle;
                line-height: 1;
                margin-bottom: 0;
            }

            &-content {
                display: inline-block;
                vertical-align: middle;
                font-size: 24px;

                input {
                    width: 400px;
                    height: 40px;
                    text-indent: 5px;
                    border-radius: 4px;
                    border: 1px solid #979797;

                    &::placeholder {
                        color: #999;
                    }
                }

                select {
                    width: 220px;
                    text-indent: 5px;
                }
            }
        }

        .tips {
            color: #999;
            font-size: 20px;
        }
    }

    .dialog-form-item-label {
        width: 100px;

    }
</style>
